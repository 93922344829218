import { RouterProvider } from 'react-router-dom';
import './App.css';
import router from './app/router';
import { ConfigProvider, theme } from 'antd';
import { useState } from 'react';
import { ThemeContext } from './app/context/themeContext';

function App() {
  const [themeMode, setThemeMode] = useState('light');

  const toogleThemeMode = () => {
    setThemeMode(t => t == "light" ? "dark" : "light")
  }

  return (
    <ThemeContext.Provider value={{ themeMode, toogleThemeMode }}>
      <ConfigProvider theme={{
        algorithm: themeMode == "dark" ? theme.darkAlgorithm : theme.defaultAlgorithm,
        token: {
          fontFamily: "Poppins",
          colorPrimary: "#5A54F9"
        }
      }}>
        <RouterProvider router={router} />
      </ConfigProvider>
    </ThemeContext.Provider>
  );
}

export default App;
