import { Card, Typography } from "antd";
import samajikMuddeImg from "../../assets/book-cover-samajik-mudde.jpeg"

const { Paragraph } = Typography;
const booksList = [{
    id: "samajik-mudde",
    title: "Samajik Mudde",
    reviewedBy: "Ramanand Rathi",
    author: "Dr. G L Sharma",
    srcImg: samajikMuddeImg,
    amazonLink: "https://amzn.eu/d/d31Mqw9",
    flipkartLink: "https://dl.flipkart.com/s/b5UCbFNNNN",
    cards: [
        <Card title="MUST READ BOOK for success in competitive exams" key="must-read">
            <Paragraph>
                SOCIAL ISSUES
                <br />(Included Economic, Political, Administrative, Legal, Gender, Environmental and Global Issues)
                <br />The PANACEA Research Foundation has been presenting completely authentic and goal based text materials for various competitive exams like IAS, RAS, RJS, NET - SLET etc for a decade. New amended edition of RAS Officer Dr GL Sharma&apos;s popular book &apos;Social Issue&apos; has been published recently. Remarkable that this book has gained record popularity among students of North India especially for Civil Services during the last seven years.
                <br />Along with competitive exams like IAS, RAS, RJS, PCS, this book has also proven equally useful for ESSAY WRITING, INTERVIEWS, panel discussion, debates, group discussion etc.
                <br />PANACEA Research Foundation provides success oriented text materials for preparation of various competitive exams after an orderly, scientific and extensive research work.
                <br />Senior RAS Officer Dr. Govardhan Lal Sharma is a country-world renowned intellectual and National Award winning author.  For the past two decades he has been providing free guidance to students for preparation for Civil Service competitive exams.
                <br />Many of your books written in Hindi and English for graduate and postgraduate courses hold an established identity among these students. Some popular Books are - &apos;Modern Contemporary Higher Sociology&apos; (Rajasthan Hindi Granth Academy, Jaipur),
                <br />AAP BHI BAN SAKTE HAI RAS TOPPER - A Complete Blue Print for RAS Exam,
                <br />&apos;Public Management in India&apos; (Prabhat Prakashan, New Delhi), &apos;Modern Criminology&apos; (Awardee of Pandit Govind Vallabh Pant National Award) etc....
                <br />One reason for the interdisciplinary horizons and qualities of the subjects included in the present book is the author&apos;s higher studies in many academic fields. Author Dr. Sharma post graduated in Sociology, Political Science, Psychology, Law and Human Rights.  He is NET, SLET and Ph.D. in Sociology
                <br />Hence, this book is very useful for Competitive Exams like IAS, RAS, RJS, NET-JRF, SLET, ESSAY WRITINGS, INTERVIEWS and Group Discussions etc...
            </Paragraph>
        </Card>,
        <Card title="The Making of the Book - SAMAJIK MUDDE" key="making">
            <Paragraph>The first edition of this research based book SAMAJIK MUDDE originally took about 2 years to complete.
                <br />And now it took again two  years to even update the newly revised and enhanced version of this book.
                <br />Honorable Shri Ramanand ji Rathi Saab, member of Rajasthan Sahitya Academy and Editor of Hindi Granth Academy has been reviewed the book with lot of endeavour.
                <br />We have read the whole content many times  and tried to make it as a perfect  way to Success.
                <br />According to Rathiji - We do not make any claims, but we have made all efforts to make the book ERROR FREE, there should be no conceptual and factual error in it and also there should be no spelling error.
                <br />If any student reader finds any factual or spelling error in the book, the book will be given to them for free.
                <br />We endeavour that this book is useful in  preparation of Civil Services exams  as well as for the students of Sociology, Anthropology, Psychology, Political Science, Public Administration, Police Administration, Law Students, Gender Studies, International Relations, Environmental Studies and overall General Knowledge.
                <br />The book will prove multi use in all competitive exams specially for IAS, RAS, RJS, NET SLET etc and for ESSAY WRITINGS, Group Discussions & INTERVIEWS.
                <br />Conceptual Clarity on every issue is covered with basic knowledge, historical background, cause, results, impacts, solutions and suggestions.
                <br />The language of the book will also teach students the ART OF EXPRESSION  (Art of Summarisation and Expension), which is essential for written main exams and essay writing in Civil Service Exams like IAS, RAS, PCS RJS etc...
                <br />May the book get all your love and blessings.
                <br />Not only Teachers, Educators and Faculties would be benefited by this Research based Book but
                <br />Youths may build their bright career and will Go Places....
                <br />Best Wishes
                <br />Be Happy
                <br />Dr GL Sharma, Author
                <br />💐🙏💐
            </Paragraph>
        </Card>
    ]
}]

export default booksList;
