import PropTypes from 'prop-types';
import { Button, Card } from "antd"
import { NavLink } from "react-router-dom";
const { Meta } = Card;

const BookCard = (props) => {
    const { book = {} } = props;
    return (
        <NavLink to={`books/${book.id}`}>
            <Card
                style={{ maxWidth: 500 }}
                cover={
                    <img
                        alt={book.title}
                        src={book.srcImg}
                    />
                }
                actions={[
                    <Button key="amazon" type="text" href={book.amazonLink} target="_blank" onClick={e => e.stopPropagation()}>Buy from Amazon</Button>,
                    <Button key="flipkart" type="text" href={book.flipkartLink} target="_blank" onClick={e => e.stopPropagation()}>Buy from Flipkart</Button>,
                ]}
            >
                <Meta
                    title={book.title}
                    description={`By ${book.author}`}
                />
            </Card>
        </NavLink>
    )
}

BookCard.propTypes = {
    book: PropTypes.object,
};

BookCard.defaultProps = {
    book: {},
};

export default BookCard;
