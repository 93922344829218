import { useLoaderData } from "react-router-dom";
import { Button, Card, Col, Descriptions, Image, Row, Space } from "antd"
import booksList from "./booksList";
import vid1 from '../../assets/samajik-mudde.mp4';
import ReactPlayer from 'react-player/lazy'

export async function bookLoader({ params }) {
    const book = booksList.find(b => b.id == params.bookId);
    return { book };
}

const BookDetail = () => {
    const { book } = useLoaderData();

    const bookDescriptionItems = [{
        label: 'Author',
        children: book.author,
    }, {
        label: 'Reviewed by',
        children: book.reviewedBy,
    }, {
        label: 'Publisher',
        children: 'PANACEA Research Foundation'
    }];
    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            <Card
                title={book.title}
                actions={[
                    <Button key="amazon" type="text" href={book.amazonLink} target="_blank" onClick={e => e.stopPropagation()}>Buy from Amazon</Button>,
                    <Button key="flipkart" type="text" href={book.flipkartLink} target="_blank" onClick={e => e.stopPropagation()}>Buy from Flipkart</Button>,
                ]}
            >
                <ReactPlayer controls url={vid1} width="100%" style={{
                    background: "black",
                    borderRadius: 8,
                    overflow: "hidden",
                    marginBottom: 16
                }} />
                <Row gutter={[12, 12]}>
                    <Col xs={24} md={12}>
                        <Image
                            alt={book.title}
                            src={book.srcImg}
                            style={{ borderRadius: "8px", overflow: "hidden", width: "100%" }}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <Descriptions
                            items={bookDescriptionItems}
                            column={1}
                        />
                    </Col>
                </Row>
            </Card>
            {book.cards || []}
        </Space>
    )
}

export default BookDetail;
