import { Carousel, Image, Space } from "antd";
import Books from "../books/books";
import img1 from '../../assets/carousel-img1.jpeg';
import img2 from '../../assets/carousel-img2.jpeg';

export default function Home() {
    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            <Carousel autoplay effect="fade" style={{ borderRadius: "8px", overflow: "hidden" }}>
                <Image src={img2} preview={false} />
                <Image src={img1} preview={false} />
            </Carousel>
            <Books />
        </Space>
    )
}
