import { Card, Space, Typography } from "antd";

const { Paragraph } = Typography;

const AboutUs = () => {
    return (
        <Space direction="vertical">
            <Card title="About Us">
                <Paragraph>
                    <strong>PANACEA RESEARCH FOUNDATION</strong> is one of the leading independent publishing houses in India with focused standard academic  publishing in Social Sciences and Humanities.
                </Paragraph>
                <Paragraph>We are publishing standard  and error-free  books for Civil Service Examinations. <strong>PANACEA RESEARCH FOUNDATION</strong> is also publishing  an International Research Journal (PANACEA INTERNATIONAL RESEARCH JOURNAL - A QUARTERLY BILINGUAL REFERRED JOURNAL) for a decade in the areas of multidisciplinary  knowledge and subjects.</Paragraph>
                <Paragraph>In our publications, we endeavor to bring out the best work, hence we collaborate with only the best minds in the field to deliver quality to our readers.</Paragraph>
                <Paragraph>Initially with few titles, we have concentrated on nationwide promotion, sales and distribution network to reach out to a wide range of academic and professional readers.</Paragraph>
                <Paragraph>We look forward to discussing new projects with potential authors and welcome suggestions on our published works.</Paragraph>
                <Paragraph>Books are the real best friends of everyone. Today is the age of knowledge, we must promote Young Generation to read books for their harmonious development.</Paragraph>
            </Card>
        </Space>
    )
}

export default AboutUs;
