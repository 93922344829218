import { Button, Card, Typography, theme } from "antd"
const { Paragraph } = Typography;
const { useToken } = theme;

const JoinMailList = () => {
    const { token } = useToken();
    return (
        <Card title="JOIN OUR MAILING LIST">
            <Paragraph>Receive email updates on the new books & offers for the subjects of interest to you</Paragraph>
            <Button style={{ paddingLeft: "0", color: token.colorPrimary }} type="link" href="mailto:panacearesearchfoundation@gmail.com">panacearesearchfoundation@gmail.com</Button>
        </Card>
    )
}

export default JoinMailList;
