import { Card, Space } from "antd";

const Guidelines = () => {
    return (
        <Space direction="vertical">
            <Card title="Guidelines" >
                <p>We are primarily an academic publisher with focused publishing in the areas of  Competitive Exams like Civil Services Exams of UPSC, RPSC and PCSs.</p>
                <p>We also publishing standard reference books of Social Sciences and Humanities for students and academics in higher education.</p>
                <p>At the initial stage, we prefer to receive a proposal rather than a complete manuscript. In order to help us assess and respond to your proposal quickly and efficiently, we would appreciate if you include the following information in your proposal:</p>
            </Card>
            <Card title="Format" >
                
            <h4>Title</h4>
            <p>Although provisional at this stage, a good title is enormously helpful to decide about a book’s publication. The title should be from a broader perspective, informative and simple.
                We ask you to suggest at least 2 – 3 alternative book titles.
            </p>
            <h4>Description</h4>
            <p>Enclose a  400-word summary of the book as an Abstract.</p>
            <h4>Content</h4>
            <p>Contents should be clear and informative. Chapter headings and main subheadings within the chapters should be provided. If your work is an edited volume, include a list of contributors including their affiliations (Authors are only responsible for procuring any necessary permissions).</p>
            <h4>Sample Chapter</h4>
            <p>We would appreciate receiving a sample chapter of the book along with one sample page of references or bibliography.</p>
            <h4>Technical Details</h4>
            <p>Provide details of the software. Proposed length of the book (number of pages and word count). Details about photographs, tables, maps, illustrations etc included.</p>
            <h4>Information about Yourself</h4>
            <p>Provide your latest Profile with brief details of relevant lecturing, research, writing, publications, etc. The Profile or CV should have your complete contact details including email and mobile numbers.</p>
            </Card>
            <Card title="Submitting your Proposal" >
                <div>Please submit your proposal by email to the following address:</div>
                <strong>
                    <div>panacearesearchfoundation@gmail.com</div>
                    <div>Ramanand Rathi (Director)</div>
                    <div>+91- 9414778452</div>
                </strong>
                <br></br>
                <div>We look forward to receiving and assessing your proposal.</div>
            </Card>
            <Card>
                <strong>Please DO NOT send the soft copy files of your complete manuscript in the initial stage. We will ask for the complete file only after your proposal has been finalized.</strong>
            </Card>
        </Space>
    );
}

export default Guidelines;
