import { Result } from "antd"

const BookError = () => {

    return (
        <Result status="warning"
            title="There are some problems with your operation."></Result>
    )
}

export default BookError;
