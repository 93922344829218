import { NavLink } from "react-router-dom";
import { Button, Result, Space } from "antd";

const Error404 = () => {
    return (
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={(<Space>
                <NavLink to="/">
                    <Button type="primary">Go back home</Button>
                </NavLink>
                {/* <NavLink to="/aboutus">
                    <Button>Contact support &rarr;</Button>
                </NavLink> */}
            </Space>)}
        />
    );
}

export default Error404;
