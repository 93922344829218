import { useContext, useState } from "react";
import { NavLink, Outlet } from 'react-router-dom';
import { Button, Drawer, Image, Layout, Space, Switch, Typography, theme } from "antd";
import "./layout.css";
import appLogo from "../assets/logo.png";
import JoinMailList from "../components/joinmaillist";
import { ThemeContext } from "../context/themeContext";
import { BgColorsOutlined, FacebookOutlined, InstagramOutlined, MenuFoldOutlined, MenuUnfoldOutlined, TwitterOutlined, YoutubeOutlined } from "@ant-design/icons";

const { Header, Footer, Content } = Layout;
const { useToken } = theme;

const navItems = [
    { name: 'Home', href: '/' },
    // { name: 'Books', href: '/books' },
    { name: 'Guidelines', href: '/guidelines' },
    { name: 'About Us', href: '/aboutus' },
]

const socialLinks = [
    { name: "Facebook", icon: FacebookOutlined, href:"https://www.facebook.com/DrGLSharma"},
    { name: "Twitter", icon: TwitterOutlined, href:"https://www.twitter.com/drglsharma"},
    { name: "Instagram", icon: InstagramOutlined, href:"https://www.instagram.com/drglsharmaras"},
    { name: "YouTube", icon: YoutubeOutlined, href:"https://www.youtube.com/drgls100"},
];

const appTitle = "Panacea Publications";

const AppLayout = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const { token } = useToken();
    const { toogleThemeMode } = useContext(ThemeContext);
    return (
        <div style={{ background: token.colorBgLayout }}>
            <div style={{ maxWidth: "1280px", margin: "auto" }}>
                <Layout>
                    <Header className="header" style={{ background: token.colorBgContainer }}>
                        <NavLink href="/">
                            <Image alt={appTitle} src={appLogo} preview={false} height={32} style={{ padding: "0 16px", opacity: 0 }} />
                            <div className="header-logo-mask" style={{ maskImage: `url(${appLogo})`, WebkitMaskImage: `url(${appLogo})`, background: token.colorPrimary }} />
                        </NavLink>
                        <span style={{ flex: "1" }} />
                        <Switch className="header-theme-switch" onChange={toogleThemeMode} checkedChildren={<BgColorsOutlined />} unCheckedChildren={<BgColorsOutlined />} />
                        <Space className="header-nav-menu">
                            {navItems.map((item) => (
                                <NavLink to={item.href} key={item.name}>
                                    {({ isActive }) => (
                                        <Button type={isActive ? "primary" : "text"}>
                                            {item.name}
                                        </Button>
                                    )}
                                </NavLink>)
                            )}
                        </Space>
                        <Button
                            className="header-slider-button"
                            type="text"
                            icon={drawerOpen ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
                            onClick={() => setDrawerOpen(!drawerOpen)}
                            style={{
                                fontSize: '16px',
                                width: 64,
                                height: 64,
                                color: token.colorPrimary
                            }}
                        />
                    </Header>
                    <Content style={{ padding: "16px" }}>
                        <Outlet />
                    </Content>
                    <div style={{ margin: "0px 16px 16px" }}>
                        <JoinMailList />
                    </div>
                    <Footer className="footer" style={{ background: token.colorBgContainer }}>
                        <div className="footer-div">
                            <div style={{ flex: 1 }}>
                                <Typography style={{ color: token.colorTextHeading }}>{appTitle}</Typography>
                                <Button style={{ paddingLeft: "0", color: token.colorPrimary }} type="link" href="mailto:panacearesearchfoundation@gmail.com">panacearesearchfoundation@gmail.com</Button>
                                <Typography style={{ color: token.colorTextHeading }}>Follow Us</Typography>
                                <div style={{ display: 'flex', gap: "2px", marginTop: 8 }}>
                                    {socialLinks.map(l => (<Button key={l.name} icon={<l.icon />} target="_blank" href={l.href} />))}
                                </div>
                            </div>
                            <div className="footer-right-div">
                                <Typography style={{ color: token.colorTextHeading }}>Guidelines</Typography>
                                <NavLink to="/guidelines">
                                    <Button style={{ paddingRight: "0", paddingLeft: "0", color: token.colorPrimary }} type="link">For Authors</Button>
                                </NavLink>
                                <Typography style={{ color: token.colorTextHeading }}>Other Links</Typography>
                                <Button style={{ paddingRight: "0", paddingLeft: "0", color: token.colorPrimary }} type="link" target="_blank" href="https://journalpanacea.com">Journal Panacea</Button>
                            </div>
                        </div>
                        <div className="copyright">Copyright @2023 | Developed by <a href="https://rekhansh.com" target="_blank" rel="noreferrer" style={{ color: token.colorPrimary }}>RekhAnsh</a></div>
                    </Footer>
                </Layout>
                <Drawer title={appTitle} placement="right" onClick={() => setDrawerOpen(false)} open={drawerOpen}>
                    <Space direction="vertical" style={{ width: "100%" }}>
                        {navItems.map((item) => (
                            <NavLink to={item.href} key={item.name}>
                                {({ isActive }) =>
                                (<Button type={isActive ? "primary" : "text"} style={{ width: "100%" }}>
                                    {item.name}
                                </Button>)
                                }
                            </NavLink>
                        ))}
                    </Space>
                </Drawer>
            </div>
        </div>
    )
}

export default AppLayout;
