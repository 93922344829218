import { Space, Typography } from "antd"
import BookCard from "../../components/bookcard";
import booksList from "./booksList";

const Books = () => {
    return (
        <>
            <Typography.Title>Published Books</Typography.Title>
            <Space>
                {booksList.map(book => (
                    <BookCard book={book} key={book.id} />
                ))}
            </Space>
        </>
    )
}

export default Books;
