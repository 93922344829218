import { createBrowserRouter } from "react-router-dom";
import Error404 from "./routes/404/error404";
import AppLayout from "./routes/layout";
import Home from "./routes/home/home";
import AboutUs from "./routes/aboutus/aboutus";
import BookDetail, { bookLoader } from "./routes/books/bookDetail";
import BookError from "./routes/books/bookError";
import Guidelines from "./routes/guidelines/guidelines";

const router = createBrowserRouter([
    {
        path: "/",
        element: <AppLayout />,
        children: [
            {
                path: "/",
                element: <Home />
            },
            {
                path: "/books/:bookId",
                element: <BookDetail />,
                loader: bookLoader,
                errorElement: <BookError />
            },
            {
                path: "/aboutus",
                element: <AboutUs />
            },
            {
                path: "/guidelines",
                element: <Guidelines />
            },
            {
                path: "*",
                element: <Error404 />,
            },
        ]
    },
]);

export default router;
